export default {
  namespaced: true,
  state: {
    branch: {
      id: null,
      name: null,
    },
    staff: {
      id: null,
      name: null,
    },
    service: {
      id: null,
      name: null,
      price: 0,
      duration: 0
    },
    schedule: {
      start_at: null,
      end_at: null,
    },
    otherData: {},
    stepList: [
      { name: "LiffReservationBranch", enabled: true },
      { name: "LiffReservationStaff", enabled: true },
      { name: "LiffReservationService", enabled: true },
      { name: "LiffReservationSchedule", enabled: true },
      { name: "LiffReservationConfirm", enabled: true },
      { name: "LiffReservationSuccess", enabled: true },
    ]
  },
  getters: {
    service({ service }) {
      return service;
    },
    schedule({ schedule }) {
      return schedule;
    },
    staff({ staff }) {
      return staff;
    },
    branch({ branch }) {
      return branch;
    },
    otherData({ otherData }) {
      return otherData;
    },
    stepList({ stepList }) {
      return stepList;
    }
  },
  mutations: {
    SET_BRANCH_DATA(state, item) {
      state.branch = {
        id: item.id,
        name: item.name,
      };
    },
    SET_SERVICE_DATA(state, item) {
      state.service = {
        id: item.id,
        name: item.name,
        price: item.price ?? 0,
        duration: item.duration ?? 0
      };
    },
    SET_STAFF_DATA(state, item) {
      state.staff = {
        id: item.id,
        name: item.name,
      };
    },
    SET_SCHEDULE_DATA(state, item) {
      state.schedule = {
        start_at: item.start_at,
        end_at: item.end_at,
      };
    },
    SET_OTHER_DATA(state, item) {
      state.otherData = item;
    },
    SET_STEP_LIST(state, list) {
      state.stepList = list;
    }
  },
  actions: {
    setBranchData(context, item) {
      context.commit("SET_BRANCH_DATA", item);
    },
    setStaffData(context, item) {
      context.commit("SET_STAFF_DATA", item);
    },
    setServiceData(context, item) {
      context.commit("SET_SERVICE_DATA", item);
    },
    setScheduleData(context, item) {
      context.commit("SET_SCHEDULE_DATA", item);
    },
    setOtherData(context, item) {
      context.commit("SET_OTHER_DATA", item);
    },
    initStepList(context, preset) {
      const list = [
        { name: "LiffReservation", enabled: true },
        { name: "LiffReservationBranch", enabled: preset?.config?.enabled_selected_branch },
        { name: "LiffReservationStaff", enabled: preset?.config?.enabled_selected_staff },
        { name: "LiffReservationService", enabled: !!preset.config?.service?.collection_id },
        { name: "LiffReservationSchedule", enabled: true },
        { name: "LiffReservationConfirm", enabled: true },
        { name: "LiffReservationSuccess", enabled: true },
      ]
      context.commit("SET_STEP_LIST", list.filter((list) => list.enabled));
    }
  },
};
